import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';


// const getApproverbyId = async approver => {
//   const res = await http.get(`${BASE_URL}/form_approver_group/${approver}`);
//   return assert(res, res.data, 'Retrieval of approver by its ID failed', res);
// };

const updateStatus = async data => {
  if (data.status === 'Closed') {
    const res = await http.post(`${BASE_URL}/form/${data.id}/close_form/`, data);
    return assert(res, res.data, 'Failed to update ePTw status', res);
  }
  else {
    const res = await http.post(`${BASE_URL}/form/${data.id}/update_approval/`, data);
    return assert(res, res.data, 'Failed to update ePTw status', res);
  }
};


const approveRejectService = {
  updateStatus
};

export default approveRejectService;