import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import eptwService from './eptwService';
import { smartTryCatch } from '../../../utils';

const initState = {
  // Assets
  eptws: [],
  eptw: {},
  unAssignedPtw: [],
  TicketActivity :[],
  fileExport  : [],
  ePTWSearchTerm : '',
  tab : "Eptw-tab",
  searchValue: "",
  // modals
  EptwModalDelete: false,
  EptwModalUpdate: false,
  EptwModalCreate: false,
  isAssetExported: false,

  // Eptw fields
  title: "",
  description: "",
  site: "",
  workPlace: "",
  documentNumber: "",
  permitNumber: "",
  workType: "",
  templateId: [],
  attributes: [],
  contractor: "",
  startDate: "",
  endDate: "",
  expiryDate: "",

  contractorFirstName: "",
  contractorLastName: "",
  subcontractorName: "",
  subcontractorRepresentative: "",
  contractorEmail: "",
  companyName: "",
  contractorPhoneNumber: "",
  companyContactNumber: "",
  idType : "",
  passportNumber: "",
  LicenseNumber: "",
  greenCardNumber: "",
  mykadNumber: "",
  sections:[],
  hirarcSections:[],
  forms:[],
  // success states
  isEptwGetSuccess: false,
  isEptwCreateSuccess: false,
  isEptwDeleteSuccess: false,
  isEptwUpdateSuccess: false,
  isDeleteTicketAttachmentSuccess: false,
  assignApproverSuccess:false,
  isEptwFillSuccess : false,

  // loading states
  isEptwCreateLoading: false,
  isEptwGetLoading: false,
  isEptwsGetLoading: false,
  isEptwDeleteLoading: false,
  isEptwUpdateLoading: false,
  assignApproverLoading:false,
  isEptwFillLoading : false,

  // error states
  isEptwGetError: false,
  isEptwCreateError: false,
  isEptwDeleteError: false,
  isEptwUpdateError: false,
  assignApproverError:false,
  isEptwFillError : false,

};

export const createEptw = createAsyncThunk('createEptw', async (data, thunkAPI) => {
  const createEptwRes = await smartTryCatch(eptwService.createEptw, data, thunkAPI);
  return createEptwRes;
});

export const getEptwsByAuthentication = createAsyncThunk(
  'getEptwsByAuthentication',
  async (data, thunkAPI) => {
    const getEptws = await smartTryCatch(
      eptwService.getEptwsByAuthentication,
      data,
      thunkAPI,
    );
    return getEptws;
  },
);


export const getEptwById = createAsyncThunk('getEptwById', async (data, thunkAPI) => {
  const getEptw = await smartTryCatch(eptwService.getEptwById, data, thunkAPI);
  return getEptw;
});

export const deleteEptw = createAsyncThunk('deleteEptw', async (data, thunkAPI) => {
  const deleteEptwRes = await smartTryCatch(eptwService.deleteEptw, data, thunkAPI);
  return deleteEptwRes;
});


export const updateEptw = createAsyncThunk('updateEptw', async (data, thunkAPI) => {
  const updateEptwRes = await smartTryCatch(eptwService.updateEptw, data, thunkAPI);
  return updateEptwRes;
});



export const getFillEptw = createAsyncThunk('getFillEptw', async (data, thunkAPI) => {
  const getFillEptwRes = await smartTryCatch(eptwService.getFillEptw, data, thunkAPI);
  return getFillEptwRes;
});
export const createFillEptw = createAsyncThunk('createFillEptw', async (data, thunkAPI) => {
  const createFillEptwRes = await smartTryCatch(eptwService.createFillEptw, data, thunkAPI);
  return createFillEptwRes;
});
export const updateFillEptw = createAsyncThunk('updateFillEptw', async (data, thunkAPI) => {
  const updateFillEptwRes = await smartTryCatch(eptwService.updateFillEptw, data, thunkAPI);
  return updateFillEptwRes;
});
export const assignApprover = createAsyncThunk('assignApprover', async (data, thunkAPI) => {
  const assignApproverRes = await smartTryCatch(eptwService.assignApprover, data, thunkAPI);
  return assignApproverRes;
});
export const unassignedApprover = createAsyncThunk('unassignedApprover', async (data, thunkAPI) => {
  const unassignedApproverRes = await smartTryCatch(eptwService.unassignedApprover, data, thunkAPI);
  return unassignedApproverRes;
});

export const EptwSlice = createSlice({
  name: 'eptw',
  initialState: initState,
  reducers: {
    reset: (state) => {
      state.isEptwCreateError = false;
      state.isEptwCreateSuccess = false;
      state.isEptwCreateLoading = false;
      state.isEptwGetError = false;
      state.isEptwGetSuccess = false;
      state.isEptwGetLoading = false;
      state.isEptwDeleteError = false;
      state.isEptwDeleteSuccess = false;
      state.isEptwDeleteLoading = false;
      state.isEptwUpdateError = false;
      state.isEptwUpdateSuccess = false;
      state.isEptwUpdateLoading = false;
      state.isEptwsGetLoading = false;
      state.assignApproverLoading = false;
      state.assignApproverSuccess = false;
      state.assignApproverError = false;
      state.isEptwFillLoading = false;
      state.isEptwFillSuccess = false;
      state.isEptwFillError = false;

      // state.Eptw = null;
    },
    openEptwModalCreate: (state) => {
      state.eptwModalCreate = true;
    },
    closeEptwModalCreate: (state) => {
      state.eptwModalCreate = false;
    },
    openEptwModalUpdate: (state) => {
      state.EptwModalUpdate = true;
    },
    closeEptwModalUpdate: (state) => {
      state.eptwModalUpdate = false;
    },
    openEptwModalDelete: (state) => {
      state.eptwModalDelete = true;
    },
    closeEptwModalDelete: (state) => {
      state.eptwModalDelete = false;
    },
    setEptw: (state, action) => {
      state.eptw = action.payload;
    },
    
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    setSite: (state, action) => {
      state.site = action.payload;
    },
    setWorkPlace: (state, action) => {
      state.workPlace = action.payload;
    },
    setDocumentNumber: (state, action) => {
      state.documentNumber = action.payload;
    },
    setPermitNumber: (state, action) => {
      state.permitNumber = action.payload;
    },
    setWorkType: (state, action) => {
      state.workType = action.payload;
    },
    setTemplateId: (state, action) => {
      state.templateId = action.payload;
    },
    setAttributes: (state, action) => {
      state.attributes = action.payload;
    },
    setContractor: (state, action) => {
      state.contractor = action.payload;
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setExpiryDate: (state, action) => {
      state.expiryDate = action.payload;
    },
    setIdType: (state, action) => {
      state.idType = action.payload;
    },
    setContractorName: (state, action) => {
      state.contractorFirstName = action.payload;
    },
    setContractorLastName: (state, action) => {
      state.contractorLastName = action.payload;
    },
    setSubcontractorName: (state, action) => {
      state.subcontractorName = action.payload;
    },
    setsubcontractorRepresentative :(state,action)=>{
      state.subcontractorRepresentative = action.payload;
    },
    setContractorEmail: (state, action) => {
      state.contractorEmail = action.payload;
    },
    setContractorPhoneNumber: (state, action) => {
      state.contractorPhoneNumber = action.payload;
    },
    setCompanyName: (state, action) => {
      state.companyName = action.payload;
    },
    setCompanyContactNumber: (state, action) => {
      state.companyContactNumber = action.payload;
    },
    setPassportNumber: (state, action) => {
      state.passportNumber = action.payload;
    },
    setLicenseNumber: (state, action) => {
      state.licenseNumber = action.payload;
    },
    setGreenCardNumber: (state, action) => {
      state.greenCardNumber = action.payload;
    },
    setMykadNumber: (state, action) => {
      state.mykadNumber = action.payload;
    },
    setSections: (state, action) => {
      state.sections = action.payload;
    },
    setHirarcSections: (state, action) => {
      state.hirarcSections = action.payload;
    },
    setForms: (state, action) => {
      state.forms = action.payload;
    },

    updateEptws : (state, action)=>{
      state.eptws = state.eptws?.map(item => item.id === action.payload.id ? action.payload : item)
    },
    setEptw : (state, action)=>{
      state.eptw = action.payload;
    },
    setEptwSearchTerm :(state,action)=>{
      state.ePTWSearchTerm = action.payload
    },
    resetEptw: (state) => {
      state.title = ""
      state.description= ""
      state.site= ""
      state.workPlace= ""
      state.documentNumber= ""
      state.permitNumber= ""
      state.workType= ""
      state.templateId= []
      state.attributes= []
      state.contractor= ""
      state.startDate= ""
      state.endDate= ""
      state.expiryDate= ""
      state.subcontractorName= ""
      state.contractorEmail= ""
      state.companyName= ""
      state.contractorPhoneNumber= ""
      state.companyContactNumber= ""
      state.subcontractorRepresentative= ""
      state.contractorName= ""
      state.contractorLastName= ""
      state.idType = ""
      state.passportNumber= ""
      state.LicenseNumber= ""
      state.greenCardNumber= ""
      state.mykadNumber= ""
      state.sections= []
      state.hirarcSections= []
      state.forms= []
      state.unAssignedPtw= []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createEptw.pending, (state) => {
        state.isEptwCreateLoading = true;
        state.isEptwCreateSuccess = false;
        state.isEptwCreateError = false;
      })
      .addCase(createEptw.fulfilled, (state, action) => {
        state.isEptwCreateLoading = false;
        state.isEptwCreateSuccess = true;
        state.isEptwCreateError = false;
        state.eptws = [...action.payload , ...state.eptws ];
      })
      .addCase(createEptw.rejected, (state) => {
        state.isEptwCreateLoading = false;
        state.isEptwCreateSuccess = false;
        state.isEptwCreateError = true;
      })
      .addCase(getEptwsByAuthentication.pending, (state) => {
        state.isEptwsGetLoading = true;
        state.isEptwGetSuccess = false;
        state.isEptwGetError = false;
      })
      .addCase(getEptwsByAuthentication.fulfilled, (state, action) => {
        state.isEptwsGetLoading = false;
        state.isEptwGetSuccess = true;
        state.isEptwGetError = false;
        state.eptws = action.payload;
      })
      .addCase(getEptwsByAuthentication.rejected, (state) => {
        state.isEptwsGetLoading = false;
        state.isEptwGetSuccess = false;
        state.isEptwGetError = true;
      })
      .addCase(getEptwById.pending, (state) => {
        state.isEptwGetLoading = true;
        state.isEptwGetSuccess = false;
        state.isEptwGetError = false;
      })
      .addCase(getEptwById.fulfilled, (state, action) => {
        state.isEptwGetLoading = false;
        state.isEptwGetSuccess = true;
        state.isEptwGetError = false;
        state.eptw = action.payload;
      })
      .addCase(getEptwById.rejected, (state) => {
        state.isEptwGetLoading = false;
        state.isEptwGetSuccess = false;
        state.isEptwGetError = true;
      })
      .addCase(deleteEptw.pending, (state) => {
        state.isEptwDeleteLoading = true;
        state.isEptwDeleteSuccess = false;
        state.isEptwDeleteError = false;
      })
      .addCase(deleteEptw.fulfilled, (state, action) => {
        state.isEptwDeleteLoading = false;
        state.isEptwDeleteSuccess = true;
        state.isEptwDeleteError = false;
        // state.eptws.results = state.eptws.results.filter((Eptw) => Eptw.id !== +action.payload);
        state.eptws = state.eptws.filter((Eptw) => Eptw.id !== +action.payload);
      })
      .addCase(deleteEptw.rejected, (state) => {
        state.isEptwDeleteLoading = false;
        state.isEptwDeleteSuccess = false;
        state.isEptwDeleteError = true;
      })
      .addCase(updateEptw.pending, (state) => {
        state.isEptwUpdateLoading = true;
        state.isEptwUpdateSuccess = false;
        state.isEptwUpdateError = false;
      })
      .addCase(updateEptw.fulfilled, (state, action) => {
        state.isEptwUpdateLoading = false;
        state.isEptwUpdateSuccess = true;
        state.isEptwUpdateError = false;
        state.eptws.results = state.eptws?.results?.map((Eptw) =>
        Eptw.id === action.payload.id ? action.payload : Eptw,
        );
        state.eptw =  action.payload
        state.files =  [];
      })
      .addCase(updateEptw.rejected, (state) => {
        state.isEptwUpdateLoading = false;
        state.isEptwUpdateSuccess = false;
        state.isEptwUpdateError = true;
      })
      .addCase(getFillEptw.pending, (state) => {
      state.isEptwsGetLoading = true;
      state.isEptwGetSuccess = false;
      state.isEptwGetError = false;
    })
    .addCase(getFillEptw.fulfilled, (state, action) => {
      state.isEptwsGetLoading = false;
      state.isEptwGetSuccess = true;
      state.isEptwGetError = false;
      state.eptw = action.payload;
    })
    .addCase(getFillEptw.rejected, (state) => {
      state.isEptwsGetLoading = false;
      state.isEptwGetSuccess = false;
      state.isEptwGetError = true;
    }) 
    .addCase(createFillEptw.pending, (state) => {
      state.isEptwFillLoading = true;
      state.isEptwFillSuccess = false;
      state.isEptwFillError = false;
    })
    .addCase(createFillEptw.fulfilled, (state, action) => {
      state.isEptwFillLoading = false;
      state.isEptwFillSuccess = true;
      state.isEptwFillError = false;
      state.eptw = action.payload;
    })
    .addCase(createFillEptw.rejected, (state) => {
      state.isEptwFillLoading = false;
      state.isEptwFillSuccess = false;
      state.isEptwFillError = true;
    }) 
    .addCase(updateFillEptw.pending, (state) => {
      state.isEptwFillLoading = true;
      state.isEptwFillSuccess = false;
      state.isEptwFillError = false;
    })
    .addCase(updateFillEptw.fulfilled, (state, action) => {
      state.isEptwFillLoading = false;
      state.isEptwFillSuccess = true;
      state.isEptwFillError = false;
      state.eptw = action.payload;
      state.eptws = state.eptws.map(eptw =>
        eptw.id === action.payload.id ? action.payload : eptw )
    })
    .addCase(updateFillEptw.rejected, (state) => {
      state.isEptwFillLoading = false;
      state.isEptwFillSuccess = false;
      state.isEptwFillError = true;
    }) 
    .addCase(assignApprover.pending, (state) => {
      state.assignApproverLoading = true;
      state.assignApproverSuccess = false;
      state.assignApproverError = false;
    })
    .addCase(assignApprover.fulfilled, (state, action) => {
      state.assignApproverLoading = false;
      state.assignApproverSuccess = true;
      state.assignApproverError = false;
      state.eptws = state.eptws.map(item =>  item.id === action.payload.id ? action.payload : item);
    })
    .addCase(assignApprover.rejected, (state) => {
      state.assignApproverLoading = false;
      state.assignApproverSuccess = false;
      state.assignApproverError = true;
    }) 
    .addCase(unassignedApprover.pending, (state) => {
      state.isEptwsGetLoading = true;
      state.isEptwGetSuccess = false;
      state.isEptwGetError = false;
    })
    .addCase(unassignedApprover.fulfilled, (state, action) => {
      state.isEptwsGetLoading = false;
      state.isEptwGetSuccess = true;
      state.isEptwGetError = false;
      state.unAssignedPtw = action.payload
    })
    .addCase(unassignedApprover.rejected, (state) => {
      state.isEptwsGetLoading = false;
      state.isEptwGetSuccess = false;
      state.isEptwGetError = true;
    }) 
},
});

export const {
reset,
openEptwModalCreate,
closeEptwModalCreate,
setTitle,
setDescription,
setSite,
setWorkPlace,
setDocumentNumber,
setPermitNumber,
setWorkType,
setTemplateId,
setAttributes,
setContractor,
setStartDate,
setEndDate,
setExpiryDate,
setIdType,
resetEptw,
setContractorName,
setContractorLastName,
setSubcontractorName,
setsubcontractorRepresentative,
setContractorEmail,
setCompanyName,
setContractorPhoneNumber,
setCompanyContactNumber,
setPassportNumber,
setLicenseNumber,
setGreenCardNumber,
setMykadNumber,
setSections,
setForms,
setHirarcSections,
updateEptws,
setEptw,
setEptwSearchTerm
} = EptwSlice.actions;

export default EptwSlice.reducer;
