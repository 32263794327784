import * as React from 'react';
import { toast } from "react-toastify"
import { Link , useHistory } from "react-router-dom";
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import DetailsModal from '../eptw/ePtwModal'
import { useDispatch, useSelector } from 'react-redux';
import classess from "../../styles/asset.module.css"
import CircularProgress, {
    CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RejectModal from '../eptw/ePtwModal/rejectModal';
import DeleteModal from '../eptw/ePtwModal/deleteModal';
import ApproveModal from '../eptw/ePtwModal/approveModal';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { reset } from '../../redux/features/approveRejectPtw/approveRejectPtwSlice';
import { assignApprover, resetEptw, unassignedApprover, updateEptws, deleteEptw, reset as resetPtw, setEptw } from '../../redux/features/eptw/eptwSlice';
import TaskDeatilsPopupModal from '../project/projectTaskTable/taskDeatilsPopupModal';
import { getTaskById, openTaskDetailModal, resetTaskActions, setTask } from '../../redux/features/task/taskSclice';
import Chip from '@mui/material/Chip';
import StatusModal from '../eptw/ePtwModal/statusModal';
import FoxUserView from '../../pages/foxUserView/foxUserView';
import PopUpAddModal from '../popUpModals/popUpAddModal';
import profile from "../../assets/img/profile.png"
import { Avatar } from '@mui/material';


export default function DataTable() {
    const [isSnackbarOpen, setSnackbarOpen] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState();
    const [rows, setRows] = React.useState([]);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [isAssignModalOpen, setIsAssignModalOpen] = React.useState(false);
    const [pTWId, setPTWId] = React.useState(null);
    const [assignPersonId, setAssignPersonId] = React.useState(null);
    const [isRejectModalOpen, setIsRejectModalOpen] = React.useState(false);
    const [isStatusModalOpen, setIsStatusModalOpen] = React.useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
    const [isError, setIsError] = React.useState(false)
    const [newStatus, setNewStatus] = React.useState('')
    const { eptws, unAssignedPtw, assignApproverSuccess, ePTWSearchTerm, isEptwDeleteSuccess } = useSelector(state => state.eptw)
    const { user } = useSelector(state => state.user)
    const signUrlImg = user?.signature
    const { isAproveRejectUpdateSuccess, isAproveRejectUpdateError, approvesStatus } = useSelector(state => state.approveReject)
    const { isgetTaskSuccess, isgetTaskError } = useSelector(state => state.task)
    const history = useHistory()
    const handleCopyClick = async (params) => {
        // Logic to copy text to clipboard goes here
        await navigator.clipboard.writeText(params.row.link);
        setSnackbarOpen(true);
    };
    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };
    React.useEffect(() => {
        setRows(eptws?.map((eptw, index) => (
            {
                ...eptw,
                id: eptw?.id,
                ePTWName: eptw?.title,
                eptWType: eptw?.work_type,
                EptwDescription: eptw?.work_description,
                status: eptw?.status,
                link: eptw?.link,
                key: index,
                category : eptw?.category === "HIRAC" ? "HIRARC" : eptw?.category
            }
        )))
    }, [eptws, ePTWSearchTerm])

    const dispatch = useDispatch()
    const handleRowClick = (params) => {
        // setSelectedRow(params.row);
        // setIsModalOpen(true);
        dispatch(setEptw(params.row))
    };
    const handleOpenDialog = async (id) => {
        await dispatch(unassignedApprover(id))
        setIsAssignModalOpen(true);
        setPTWId(id)
        setAssignPersonId(null)
    };
    const handleCloseDialog = (id) => {
        setIsAssignModalOpen(false);
        setPTWId(null)
        setAssignPersonId(null)
    };
    const handelSelectedPerson = (e) => {
        setAssignPersonId(e.target.value)
    };
    const handleCreateSave = () => {
        const data = { pTWId, formdata: { approver: assignPersonId } }
        if (assignPersonId) {
            dispatch(assignApprover(data))
        } else {
            toast.warning("Please Select An Approver", {
                toastId: 'please Select An Approver',
            })
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        dispatch(resetEptw())
    };
    const handleCloseRejectModal = () => {
        setIsRejectModalOpen(false);
        setIsError(false)
    };
    const handleCloseStatusModal = () => {
        setIsStatusModalOpen(false);
    };
    const handleCloseDeleteModal = () => {
        setIsDeleteModalOpen(false);
    };

    const handelgetTask = (item) => {
        dispatch(getTaskById(item?.id))
    }

    React.useEffect(() => {
        if (isgetTaskError) {
            toast.error("You Don't have a permission", {
                toastId: 'permission',
            });
        } else if (isgetTaskSuccess) {
            dispatch(openTaskDetailModal())
        }
        dispatch(resetTaskActions())
    }, [isgetTaskSuccess, isgetTaskError])

    function displayToast() {
        if (isAproveRejectUpdateSuccess) {
            toast.success('Status Updated successfully', {
                toastId: 'AproveRejectUpdateSuccess',
            });
            dispatch(reset());
            dispatch(updateEptws(approvesStatus));
            setIsRejectModalOpen(false);
            setIsStatusModalOpen(false);
        }
        if (isAproveRejectUpdateError) {
            toast.error('Error Updating Status', {
                toastId: 'ApproverCreateError',
            });
            dispatch(reset());
        }
        if (assignApproverSuccess) {
            toast.success('Approver Added successfully', {
                toastId: 'AproveRejectUpdateSuccess',
            });
            dispatch(resetPtw());
            setIsAssignModalOpen(false)
        }
        if (isEptwDeleteSuccess) {
            toast.success('Permit Deleted successfully', {
                toastId: 'PermitDeleteSuccess',
            });
            dispatch(resetPtw());
        }
    }
    function stringAvatar(name1,name2, image) {
        if (image && !image.includes('no_profile')) {
          return {
            src: image
          };
        } else {
        const initials = name1.charAt(0).toUpperCase()+name2.charAt(0).toUpperCase()||"--"
      return {
            children: initials
          };
        }
      }
    const columns = React.useMemo(() => [
        // {
        //     field: 'Detail view',
        //     headerName: '',
        //     width: 50,
        //     sortable: false, // Disable sorting for this column
        //     disableColumnMenu: true, // Disable the entire column menu
        //     renderCell: (params) => (
        //         // <button className='p-2' onClick={() => handleRowClick(params)} ><FontAwesomeIcon icon={faUpRightFromSquare} /></button>
        //         <Link className='p-2 black' onClick={() => handleRowClick(params)} to={`/forms/${params?.row?.id}`} ><FontAwesomeIcon icon={faUpRightFromSquare} /></Link>
        //     ),
        // },
        { field: 'id', headerName: 'Permit Number', width: 50 },

        { field: 'eptWType', headerName: 'Permit Type', width: 130 },
        { field: 'category', headerName: 'Category', width: 130 },
        {
            field: 'approver',
            // type: 'actions',
            headerName: 'Approvers',
            width: 250,
            editable: false,
            renderCell: (params) => (
                    <>
                        {params.row?.approvals?.map((item, index) => (
                            <Avatar key={index} {...stringAvatar(item?.approver?.first_name||"",item?.approver?.last_name||"",item?.approver?.image)} title={item?.approver?.first_name||"contractor"} alt={item?.approver?.first_name||"contractor"} />
                        ))}
                    </> 
            ),
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 120,
            renderCell: (params) => (
                params.row?.status === 'Pending' ?
                    <Chip label="Pending" color="warning" variant="outlined" /> :
                    params.row?.status === 'Submitted' ? <Chip label="Submitted" color="primary" variant="outlined" /> :
                        params.row?.status === 'Resubmitted' ? <Chip label="Resubmitted" color="primary" variant="outlined" /> :
                            params.row?.status === 'Approved' ? <Chip label="Approved" color="success" variant="outlined" /> :
                                params.row?.status === 'Closed' ? <Chip label="Closed" color="primary" variant="outlined" /> :
                                    <Chip label="Rejected" color="error" variant="outlined" />
            ),
        },
        {
            field: 'progress',
            headerName: 'Progress',
            sortable: false, // Disable sorting for this column
            width: 120,
            renderCell: (params) => (
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                    <CircularProgress style={{ color: '#f63854' }} variant="determinate" value={Math.round(params.row.status === 'Pending' ? 20 : params.row.status === 'Submitted' ? 40 : params.row.status === 'Rejected' ? 50 : params.row.status === 'Closed' ? 100 : params.row.status === 'Approved' ? 80 : 80)} />
                    <Box
                        sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography
                            variant="caption"
                            component="div"
                            color="#f63854"

                        >
                            {`${Math.round(params.row.status === 'Pending' ? 20 : params.row.status === 'Submitted' ? 40 : params.row.status === 'Rejected' ? 50 : params.row.status === 'Closed' ? 100 : params.row.status === 'Approved' ? 80 : 80)}%`}</Typography>
                    </Box>
                </Box>
            ),
        },
        {
            field: 'tasks',
            headerName: 'Projects',
            width: 200,
            renderCell: (params) => (
                params.row.tasks?.slice(0, 3)?.map((item, index) => (
                    <div key={index} className={classess.eptwButtons}>
                        {console.log("full item",item)}
                        <Button key={index} title={item?.project} variant="contained" size="small"
                            onClick={() => {
                                handelgetTask(item)
                            }}
                        >
                            {`${item?.name?.length > 10 ? item?.project?.slice(0, 10) + `...` : item?.project}`}
                        </Button>
                    </div>))
            ),
        },
        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false, // Disable sorting for this column
            width: 250,
            renderCell: (params) => {
                let deleteBtn = (
                    <span
                        title="Delete permit"
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsDeleteModalOpen(true)
                            setSelectedRow(params.id)
                        }
                        }
                        id={params.row.id}
                        className={`tertiary-btn btn`}
                        style={{
                            marginLeft: '7px',
                            borderRadius:'7px',
                            padding:'2px',
                            cursor: 'pointer',
                            color: 'transparent',
                            transition: 'color 0.1s',
                        }}
                    >
                        <div
            style={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '5px',
                transition: 'background-color 0.1s',
                borderRadius: '5px',
                width: '40px',
                fontSize: '1.3em',
                color: '#D9314A'
            }}
            className="delete-icon-wrapper"
                        >

                            <FontAwesomeIcon icon={faTrashCan} />
                        </div>
                    </span>
                );
                const actionButtons = params.row?.approvals?.filter(item => item.approver.id === user?.id)?.length !== 0 ?
                    (params.row.status === 'Closed' ?
                        <div className={classess.eptwButtons}>
                            <Button variant="contained" size="small" onClick={(e) => {
                                setIsModalOpen(true)
                                setSelectedRow(params.row);
                            }}>
                                View
                            </Button>

                        </div> :
                        params.row.status === 'Rejected' ?
                            <div className={classess.eptwButtons}>
                                <Button variant="contained" size="small" onClick={(e) => {
                                    setIsModalOpen(true)
                                    setSelectedRow(params.row);
                                }}>
                                    View
                                </Button>

                            </div> :
                            params.row.status === 'Pending' ?
                                <div className={classess.eptwButtons}>
                                    <Button variant="contained" size="small" onClick={(e) => {
                                        setIsModalOpen(true)
                                        setSelectedRow(params.row);
                                    }}>
                                        View
                                    </Button>

                                </div> :
                                params.row.status === 'Approved' ?
                                    <div className={classess.eptwButtons}>
                                        <Button variant="contained" size="small" onClick={(e) => {
                                            e.stopPropagation();
                                            if (signUrlImg) {
                                                setNewStatus("Closed")
                                                setIsStatusModalOpen(true)
                                                setSelectedRow(params.row);
                                            } else {
                                                toast.warning('Please add your Signature then try again', {
                                                    toastId: 'SignatureError',
                                                });
                                            }
                                        }}>
                                            Complete
                                        </Button>
                                    </div> : params.row?.approvals?.find(item => item.approver.id === user?.id)?.status === "Approved" ? "Approved" : <div className={classess.eptwButtons}>
                                        <Button variant="contained" size="small" onClick={(e) => {
                                            e.stopPropagation();
                                            if (signUrlImg) {
                                                setNewStatus("Approved")
                                                setIsStatusModalOpen(true)
                                                setSelectedRow(params.row);
                                            } else {
                                                toast.warning('Please add your Signature then try again', {
                                                    toastId: 'SignatureError',
                                                });
                                            }

                                        }}>
                                            Approve
                                        </Button>
                                        <Button variant="contained" size="small" onClick={(e) => {
                                            e.stopPropagation();
                                            if (signUrlImg) {
                                                setIsRejectModalOpen(true)
                                                setSelectedRow(params.row);
                                            } else {
                                                toast.warning('Please add your Signature then try again', {
                                                    toastId: 'SignatureError',
                                                });
                                            }

                                        }}>
                                            Reject
                                        </Button>
                                    </div>) : <div className={classess.eptwButtonsDisabled}>
                                        <Button variant="contained" size="small" disabled>
                                            Approve
                                        </Button>
                                        <Button variant="contained" size="small" disabled>
                                            Reject
                                        </Button>
                                    </div>
                return (
                    <div style={{width: "100%" , display: 'flex', alignItems: 'center', justifyContent: "space-between"}}>
                       <div>{actionButtons}</div> 
                        <div style={{ ml: 50 }}>
                          {user?.role==="Administrator" && deleteBtn}
                        </div>
                    </div>
                )
            },
        },
    ], [user])
    return (
        <>
            {displayToast()}
            <div style={{ height: 400, width: '100%' }}>
                <Snackbar
                    open={isSnackbarOpen}
                    autoHideDuration={3000} // Adjust the duration as needed
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert severity="error" style={{
                        color: "#f63854",
                        // color: 'white', // Text color
                    }} onClose={handleCloseSnackbar}>
                        Text copied to clipboard!
                    </Alert>
                </Snackbar>
                <div style={{ height: '100%', overflow: 'auto' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 5 },
                            },
                        }}
                        pageSizeOptions={[5, 10, 20 , 50]}
                        onRowClick={(params)=> history.push(`/forms/${params?.row?.id}`)}
                    />
                </div>
                {isAssignModalOpen && (
                    <PopUpAddModal
                        showModal={isAssignModalOpen}
                        handleClose={handleCloseDialog}
                        modalTitle="Assign Approver"
                        primaryButtonText="Assign"
                        handleCreateSave={handleCreateSave}
                    >
                        <div className="col-lg-12 col-sm-12 pt-3">
                            <label className="font-weight-normal ">Select Approver</label><label className="font-danger m-0">*</label>
                            <select className="form-control select-dash custom-select2 mb-2"
                                name="priority2" id="priority2"
                                onChange={handelSelectedPerson}
                                value={assignPersonId}
                            >
                                <option value="" selected>--SELECT--</option>
                                {unAssignedPtw?.length > 0 && unAssignedPtw?.map(user => (
                                    <option key={user?.id} value={user?.id}>{user?.name}</option>
                                ))}
                            </select>
                        </div>
                    </PopUpAddModal>
                )}
                {isModalOpen && <FoxUserView open={isModalOpen} onClose={handleCloseModal} rowData={selectedRow} />}
                {isRejectModalOpen &&
                    <RejectModal setIsError={setIsError} isError={isError}
                        open={isRejectModalOpen} onClose={handleCloseRejectModal}
                        rowData={selectedRow} />
                }
                {isDeleteModalOpen && <DeleteModal open={isDeleteModalOpen} onClose={handleCloseDeleteModal}
                    rowData={selectedRow} />}
                {isStatusModalOpen && <StatusModal open={isStatusModalOpen} onClose={handleCloseStatusModal} rowData={selectedRow} status={newStatus} />}
                <TaskDeatilsPopupModal view />
            </div>
        </>
    );
}