import * as React from 'react';
import { useState } from 'react';
import {toast} from "react-toastify"
import BasicCard from '../../components/materialCard/materialCard';
import DataTable from '../../components/dataTable/dataTable';
import { Button } from 'react-bootstrap';
import classes from './eptw.scss';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import StatCard from '../../components/statCard/statCard';
import AddEptw from '../../components/eptw/eptwDrawer/addEptw';

import Modal from '@mui/material/Modal';
import { closeEptwModalCreate, getEptwsByAuthentication, resetEptw,  reset as resetEptwStatus } from '../../redux/features/eptw/eptwSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsers } from '../../redux/features/user/userSlice';
import AddEptwModal from './componantes/AddEptwModal';
import { getContractor } from '../../redux/features/project/projectSclice';
import { getTemplates } from '../../redux/features/template/templateSclice';

export default function Eptw() {
const dispatch = useDispatch()
const {
  isEptwCreateSuccess,
  isEptwCreateError,
  eptws
} = useSelector(state => state.eptw)
  React.useEffect(() => {
    dispatch(getTemplates())
    dispatch(getContractor())
    dispatch(getEptwsByAuthentication())
    dispatch(getAllUsers());
  }, [])


  const countPending = eptws?.filter(item => item?.status === "Pending")?.length
  const countSubmitted = eptws?.filter(item => item?.status === "Submitted")?.length
  const countApproved = eptws?.filter(item => item?.status === "Approved")?.length
  const countRejected = eptws?.filter(item => item?.status === "Rejected")?.length
  const counterClosed = eptws?.filter(item => item?.status === "Closed")?.length


  const handleFormSubmit = (event) => {
    event.preventDefault();
    const newId = Math.max(...rows.map((row) => row.id)) + 1;
    const newRow = { ...formData, id: newId };
    setRows([...rows, newRow]);
    setFormData({ id: '', eptw: '', location: '', timeline: '', status: '', assignto: '' });
    closeModalCreate();
  };

  const openModal = () => {
    setOpenModalCreate(true);
  };

  const closeModalCreate = () => {
    setOpenModalCreate(false);
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const initialRows = [
    { ID: 1, eptwType: 'Room renovation', something: 'Jon', description: '12 jun 2023', eptwStatus: 'Pending' },
    { ID: 2, eptwType: 'Toilet Renovation', something: 'JonPur', description: '13 jun 2023', eptwStatus: 'Pending' },
  ];
  // States for managing component
  const [rows, setRows] = useState(initialRows);
  const [formData, setFormData] = useState({ id: '', project: '', location: '', timeline: '', status: '', assignto: '' });
  const [openModalCreate, setOpenModalCreate] = useState(false);

  const { eptwModalCreate} = useSelector(state => state.eptw)

  function displayToast() {
     if (isEptwCreateSuccess) {
      toast.success('EPTW Created successfully', {
        toastId: 'CreatedEptwsuccess',
      });
      dispatch(resetEptw())
      dispatch(resetEptwStatus())
      dispatch(closeEptwModalCreate())
    }
    else if (isEptwCreateError) {
      toast.error('Something went wrong creating EPTW Created', {
        toastId: 'CreatedEptwfaild',
      });
      dispatch(resetEptwStatus())
    }
    }

  return (
    <>
      <div className={classes.assets}>
        <AddEptw />
        {eptwModalCreate && <AddEptwModal 
          openModalCreate = {eptwModalCreate} 
          closeModalCreate={() =>{ 
            dispatch(closeEptwModalCreate())
            dispatch(resetEptw())
          }}
          />}
      </div>
      <Box ml={5}>
        <Grid  container spacing={2} >
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <StatCard count={countPending} type={'Pending Permit'} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <StatCard count={countSubmitted} type={'Submitted Permit'} sx={{ ml: 2 }} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <StatCard count={countApproved} type={'Approved Permit'} sx={{ ml: 2 }} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <StatCard count={countRejected} type={'Rejected Permit'} sx={{ bgcolor: '#red' }} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <StatCard count={counterClosed} type={'Completed Permit'} />
          </Grid>
        </Grid>
      </Box>
      <BasicCard component={<DataTable />} m={2} />
      {displayToast()}
    </>
  );

}