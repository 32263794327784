import { assert, http } from '../../../utils'
import { BASE_URL } from '../../../utils/axios'

const createTask = async task => {
  if (task.status === 'Completed') {
    task.actual_finish_date = task.finish_date
  }
  const res = await http.post(`${BASE_URL}/project_task/`, task)
  return assert(res, res.data, 'Create Task is failed', res)
}

const getTaskById = async taskID => {
  const res = await http.get(`${BASE_URL}/project_task/${taskID}`)
  return assert(res, res.data, 'Retrival of project by Project is failed')
}

const getTaskByAuthentication = async () => {
  const res = await http.get(`${BASE_URL}/project_task/`)
  return assert(res, res.data, 'Retrival of project by Project is failed')
}

const taskDelete = async taskID => {
  const res = await http.delete(`${BASE_URL}/project_task/${taskID}/`)
  return assert(res, taskID, 'Deletion of sub location failed', res)
}

const updateTask = async data => {
  if (data.status === 'Completed') {
    data.actual_finish_date = data.finish_date
  } else {
    data.actual_finish_date = null
  }

  const res = await http.put(`${BASE_URL}/project_task/${data.id}/`, data)
  return assert(res, res.data, 'Update of the Project failed', res)
}
const addPtwTask = async data => {
  const res = await http.post(
    `${BASE_URL}/form/${data.selectedPtw}/attach_task/${data.taskId}/`
  )
  return assert(res, res.data, 'Update of the Project failed', res)
}

const TaskService = {
  createTask,
  getTaskById,
  taskDelete,
  updateTask,
  getTaskByAuthentication,
  addPtwTask
}

export default TaskService
