import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const createTemplate = async (template) => {
    const res = await http.post(`${BASE_URL}/form_template/`, template)
    return assert(res, res.data, 'Create template is failed', res);
}
const getTemplates = async () => {
    const res = await http.get(`${BASE_URL}/form_template/`)
    return assert(res, res.data, 'Create template is failed', res);
}

const getTemplateById = async (templateID) => {
    const res = await http.get(`${BASE_URL}/form_template/${templateID}`)
    return assert(
        res,
        res.data,
        "Retrival of project by Project is failed"
    )
}

const templateDelete = async (templateID) => {
    const res = await http.delete(
        `${BASE_URL}/form_template/${templateID}/`
    )
    return assert(res, templateID, "Deletion of sub location failed", res);
}

const updateTemplate = async (payload) => {
    const res = await http.put(`${BASE_URL}/form_template/${payload.id}/`, payload.data);
    return assert(res, res.data, 'Update of the Project failed', res);
};
const templateService = {getTemplates ,  createTemplate, getTemplateById, templateDelete, updateTemplate }

export default templateService