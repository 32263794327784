import React, { useRef } from 'react'
import { useSelector } from 'react-redux';
import classes from "../../styles/asset.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import logo from "../../assets/img/FOX-Logo3.png"
import { useReactToPrint } from 'react-to-print';

function TemplateReview({ templateName, customSection, revisionNumber, revisionDate, documentNumber, templateCategory }) {
    const { company } = useSelector((state) => state.company);
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const hirarcPreview =   <div style={{ padding: "30px" , position: "relative" }}>
    <button style={{position: "absolute" , right: "10px" , top:"-79px" }} id='srvc-btn' type='button' className="btn secondary-btn mr-2" onClick={handlePrint} title='Print PDF'> <FontAwesomeIcon icon={faPrint} size="lg" /> </button>
    <table className={classes.table1}>
        <thead>
            <tr>
                <td>
                    <img
                        src={company?.logo || logo}
                        alt=''
                        className='brand-image img-company'
                        id='result_company_base'
                        height='100'
                        width='100'
                    />
                </td>
                <td >NORTHERN CORRIDOR IMPLEMENTATION AUTHORITY</td>
            </tr>
        </thead>
    </table>
    <table className={classes.table2}>
        <thead>
            <tr>
                <td >Hazard Identification Risk Assessment and Risk Control</td>
            </tr>
        </thead>
    </table>
    <> <table className={classes.table3}>
        <tbody>
            <tr>
                <td> Contractor Company Name <br />
                    <input type="text"
                    disabled
                    />
                </td>
                <td >Project Title <br />
                    <input type="text"
                       disabled
                    /></td>
                <td >Activity Assessed    <br />
                    <input type="text"
                        disabled/></td>
                <td >PTW No   <br />
                        <input type="text"  
                        disabled/> 
                    </td>
            </tr>
            <tr>
                <td >Conducted by  <br />
                    <input type="text"
                        disabled/></td>
                <td>Date <br />
                    <input type="date"
                        disabled
                    /></td>
                <td >Approved by:  <br />
                        <input type="text" disabled />
                </td>
                <td>Date <br />
                        <input type="date"
                        /></td>
            </tr>
        </tbody>
    </table>

        <table className={classes.table4}>
            <thead>
                <tr>
                    <td colSpan="4"> Hazard Identification  </td>
                    <td colSpan="4">Risk Assessment </td>
                    <td colSpan="1">Risk Control     </td>
                </tr>
                <tr>
                    <td >  No.   </td>
                    <td >Sequence of Activity/Task  </td>
                    <td >Potential Hazard  </td>
                    <td >Potential impact or Effects     </td>
                    <td >Existing Control Measures      </td>
                    <td >Severity      </td>
                    <td >Likelihood      </td>
                    <td >Risk Ranking       </td>
                    <td >Recommended Control Measures </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                            <td>1</td>
                                <td ><input type="text" disabled /></td>
                                <td ><input type="text" disabled /></td>
                                <td ><input type="text" disabled /></td>
                                <td ><input type="text" disabled /></td>
                                    <td >
                                        <select 
                                            style={{ fontSize: "16px", fontWeight: "normal", color: "black" }}
                                            disabled
                                            >
                                            <option value={1}>(1) Negligible (Minor abrasions, bruises, cuts, first aid type injury)    </option>
                                            <option value={2}>(2) Minor (Disabling but not permanent injury)      </option>
                                            <option value={3}>(3) Serious (Non-fatal injury, permanent disability)   </option>
                                            <option value={4}>(4) Fatal (Approximately one single fatality major property damage if hazard is realized) .  </option>
                                            <option value={5}>(5) Catastrophic (Numerous fatalities, irrecoverable property damage and productivity)      </option>
                                        </select>
                                    </td>
                                <td>
                                        <select 
                                            style={{ fontSize: "16px", fontWeight: "normal", color: "black" }}
                                            disabled
                                            >
                                            <option value={1}>(1) Inconceivable (Is practically impossible and has never occurred)  </option>
                                            <option value={2}>(2) Remote (Has not been known to occur after many years)     </option>
                                            <option value={3}>(3) Conceivable (Might be occurring at some time in future)   </option>
                                            <option value={4}>(4) Possible (Has a good chance of occurring and is not unusual)  </option>
                                            <option value={5}>(5) Most likely (The most likely result of the hazard/event being realized)  </option>
                                        </select>
                                    </td>
                                    
                                <td style={{background: "green"}}>
                                        1
                                </td>
                                <td ><input type="text" disabled /></td>
                        </tr>
            </tbody>
        </table>
    </>
    <div>
    </div>
</div>

    const pdfContent = <div style={{ paddingBottom: '1.25rem', display: 'none' }}>
    <div className="card-body" ref={componentRef}>
        {hirarcPreview}
    </div>
    </div>

    if (templateCategory === "HIRAC") {
        return <>
        {hirarcPreview}
        {pdfContent}
        </>
    }

    return (
        <div className={classes.templateCard}>
            <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-0">
                <div className="col col-lg-3 col-md-4 col-sm-4 col-xs-12  mb-2 logo float-left"
                    style={{ marginTop: "-46px" }}
                >
                    <img src={company?.logo ? company?.logo : logo} alt='logo' style={{ width: "80px", height: "80px", objectFit: "contain" , borderRadius: "50%" }} />
                </div>
                <div className="col-lg-9 col-sm-12 col-md-8 mt-5 col-xs-12">
                    <h1 style={{ fontWeight: "400", textAlign: "center" }}>{templateName}</h1>
                </div>
            </div>
            <div className="mt-5">
            <center>
                <div className="card col-lg-9 col-sm-12 col-xs-12 col-md-12 text-left"
                    style={{ display: "block" }}
                >
                    <div className="tab-content col-lg-12 col-sm-12" id="custom-tabs-two-tabContent">
                        <div id="maintenance-tab" role="tabpanel" aria-labelledby="custom-tabs-two-maintenance-tab">
                            <div className="col-lg-12 col-sm-12 pt-3">
                                <div className="document-wraper">
                                    <div>
                                        <label className="font-weight-normal">Document Number<label className="font-danger m-0">*</label></label>
                                        <input disabled required name='meter_name' type="text" className="form-control mb-2"
                                            placeholder="Document Number"
                                            value={documentNumber || ""}
                                        />
                                    </div>
                                    <div>
                                        <label className="font-weight-normal">Permit Number<label className="font-danger m-0">*</label></label>
                                        <input disabled required name='text' type="text" className="form-control mb-2"
                                            placeholder="Permit Number"
                                            value={'Auto Generated Running Number'}
                                        />
                                    </div>
                                    <div>
                                        <label className="font-weight-normal">Revision Number<label className="font-danger m-0">*</label></label>
                                        <input disabled required name='meter_name' type="text" className="form-control mb-2"
                                            placeholder="Revision Number"
                                            value={revisionNumber || ""}
                                        />
                                    </div>
                                    <div>
                                        <label className="font-weight-normal">Revision Date<label className="font-danger m-0">*</label></label>
                                        <input disabled required name='date' type="date" className="form-control mb-2"
                                            placeholder="Revision Date"
                                            value={revisionDate || ""}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body pb-3">
                                {customSection.map((item, index) => (
                                    <div className={classes.templateSection} key={index}>
                                        {console.log("the itemss", item)}
                                        <h3 className={classes.sectionTitle}>{item.title}</h3>
                                        <ul className={classes.sectionfields}>
                                            {item?.fields.map((ele, idx) => {
                                                console.log("an element", ele)
                                                const type = ele.type
                                                return (type === "String" || type === 'Date' || type === 'Number' || type === 'Attachment') ? <div className="col-lg-12 col-sm-12">
                                                    <label className="font-weight-normal">
                                                        {ele.name?.replace(/_/g, " ")}
                                                        {ele.isRequired === true && <label className="font-danger m-0">*</label>
                                                        }</label>
                                                    <input
                                                        type={type === "String" ? "Text" : type === "Number" ? "number" : type === "Date" ? "date" : type === "Number" ? "number" : "file"}
                                                        className="form-control mb-2"
                                                        disabled
                                                        // placeholder={ele.type}
                                                        placeholder={null}
                                                    />
                                                </div> : type == 'Checkbox' ? <div className="col-lg-12 col-sm-12">
                                                    <div className={classes.templateCheckbox}>
                                                        <input type="Checkbox" disabled />
                                                        <label className="font-weight-normal">{ele.name}</label>
                                                    </div> </div> :
                                                    type == 'Description' ? <div className="col-lg-12 col-sm-12"><h6>{ele.name}</h6></div>
                                                        : <div>
                                                            <label className="font-weight-normal">{ele.name}{ele.isRequired === true && <label className="font-danger m-0">*</label>}</label>
                                                            <div className="col-lg-12 col-sm-12"><div className='sign-image'><h3 style={{ marginTop: '30px', textAlign: 'center', color: '#858585' }}>{ele.name}{ele.isRequired === true && <label className="font-danger m-0">*</label>} [Signature field]</h3></div></div>
                                                        </div>
                                            })}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </center >
            </div>
        </div>
    )
}

export default TemplateReview