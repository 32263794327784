import React, { useEffect } from 'react'
import logo from "../../assets/img/FOX-Logo3.png"
import classes from "./pdf.module.css";
import { getCompanyProfile } from '../../redux/features/company/companySlice';
import { useDispatch, useSelector } from 'react-redux';
function PrintPdf({ sections, eptw }) {
   const dispatch = useDispatch();

   useEffect(() => {
      dispatch(getCompanyProfile())
   }, []);

   const { company } = useSelector((state) => state.company);
   return (
      <>
         <div className={classes.firstTable}>
            <table >
               <thead>
                  <tr>
                     <td>Document Number</td>
                     <td >NOTHERBN CORRIDOR IMPLEMENTATION AUTHORITY</td>
                  </tr>
               </thead>
               <tbody>
                  <tr>
                     <td style={{ fontSize: "14px" }}>{eptw?.document_number}</td>
                     <td style={{ background: "#cecece" }}>PERMIT TO WORK <br /> ({eptw?.work_type})</td>
                  </tr>
               </tbody>
            </table>
            <div>
               <img
                  src={company?.logo || logo}
                  alt=''
                  className='brand-image img-company'
                  id='result_company_base'
                  height='100'
                  width='100'
               />
            </div>
         </div>
         <div className={classes.secTwo}>
            {/* <div className={classes.secTwoContent}>
        <div className={classes.leftTd}>
           <h6>PERMIT NUMBER</h6> 
        </div>
        <div className={classes.rightTd}>
           <p>{eptw?.permit_number}</p> 
        </div>
    </div> */}
            {/* <div className={classes.secTwoContent}>
        <div className={classes.leftTd}>
           <h6>REVISION NUMBER</h6> 
        </div>
        <div className={classes.rightTd}>
           <p>{eptw?.revision_number}</p> 
        </div>
    </div>
    <div className={classes.secTwoContent}>
        <div className={classes.leftTd}>
           <h6>REVISION DATE</h6> 
        </div>
        <div className={classes.rightTd}>
           <p>{new Date (eptw?.revision_date).toLocaleDateString()}</p> 
        </div>
    </div>
    <div className={classes.secTwoContent}>
        <div className={classes.leftTd}>
           <h6>SITE</h6> 
        </div>
        <div className={classes.rightTd}>
           <p>{eptw?.site}</p> 
        </div>
    </div>
    <div className={classes.sectionHeader}>
        Section I - REQUESTOR INFORMATION
    </div>
    <div className={classes.secTwoContent}>
        <div className={classes.leftTd}>
           <h6>COMPANY</h6> 
        </div>
        <div className={classes.rightTd}>
           <p>{eptw?.contractor?.company_name}</p> 
        </div>
    </div>
    <div className={classes.secTwoContent}>
        <div className={classes.leftTd}>
           <h6>COMPANY CONTACT NUMBER</h6> 
        </div>
        <div className={classes.rightTd}>
           <p>{eptw?.contractor?.company_contact_number}</p> 
        </div>
    </div>
    <div className={classes.secTwoContent}>
        <div className={classes.leftTd}>
           <h6>Email</h6> 
        </div>
        <div className={classes.rightTd}>
           <p>{eptw?.contractor?.email}</p> 
        </div>
    </div>
    <div className={classes.secTwoContent}>
        <div className={classes.leftTd}>
           <h6>Phone</h6> 
        </div>
        <div className={classes.rightTd}>
           <p>{eptw?.contractor?.phone_number}</p> 
        </div>
    </div>
    <div className={classes.secTwoContent}>
        <div className={classes.leftTd}>
           <h6>SUB CONTRACTOR (if Any)</h6> 
        </div>
        <div className={classes.rightTd}>
           <p>{eptw?.sub_contractor}</p> 
        </div>
    </div>
    <div className={classes.sectionHeader}>
        Section II - WORK DETAILS
    </div>
    <div className={classes.secTwoContent}>
        <div className={classes.leftTd}>
           <h6>LOCATION OF WORK</h6> 
        </div>
        <div className={classes.rightTd}>
           <p>{eptw?.work_place}</p> 
        </div>
    </div>
    <div className={classes.secTwoContent}>
        <div className={classes.leftTd}>
           <h6>JOB / ACTIVITY DESCRIPTION</h6> 
        </div>
        <div className={classes.rightTd}>
           <p>{eptw?.work_description}</p> 
        </div>
    </div> */}
            {sections?.length > 0 && <div className="pt-3">
               {/* <label className="font-weight-normal pl-3">The Task will involve the following:</label> */}
               <div >
                  {sections?.map((item, index) => (
                     <div className={classes.templateSection} key={index}>
                        <h3 className={classes.sectionTitle}>{item?.title?.toUpperCase()}</h3>
                        <ul className={classes.sectionFeilds}>
                           {item.fields.map((ele, idx) => {
                              const type = ele.type
                              return (type === "String" || type === "Date")  ? <div className="col-lg-12 col-sm-12">
                                 <label className="font-weight-normal">{ele.name}</label>
                                 <input
                                    type={type === "String" ? "text" :"date"}
                                    className="form-control mb-2"
                                    placeholder={ele.type}
                                    value={ele.value}
                                    disabled
                                 />
                              </div> : type === "Signature" ? <div className="col-lg-12 col-sm-12">
                                 {/* <div className={classes.templateCheckbox}> */}
                                    {/* <input type="checkbox"
                                       checked={ele.value}
                                       disabled /> */}
                                       <label className="font-weight-normal">{ele.name}</label>
                                    <img
                                       src={ele?.value || null}
                                       alt='Signature missing'
                                       className='brand-image img-company'
                                       id='result_company_base'
                                       height='100'
                                       width='300'
                                    />
                                 {/* </div > */}
                                  </div>
                                 : <div className="col-lg-12 col-sm-12">
                                    <div className={classes.templateCheckbox}>
                                       <input type="checkbox"
                                          checked={ele.value}
                                          disabled />
                                       <label className="font-weight-normal">{ele.name}</label>
                                    </div> </div>
                           })}
                        </ul>
                     </div>
                  ))}
               </div>
            </div>
            }
            {/* <div className={classes.sectionHeader}>
    Section III - FACILITY MANAGER PERMIT APPROVAL
    </div>
    <div className={classes.secTwoContent}>
        <div className={classes.leftTd}>
           <h6>NAME OF ISSUER</h6> 
        </div>
        <div className={classes.rightTd}>
           <p>{" "}</p> 
        </div>
    </div>
    <div className={classes.secTwoContent}>
        <div className={classes.leftTd}>
           <h6>DESIGNATION</h6> 
        </div>
        <div className={classes.rightTd}>
           <p>{" "}</p> 
        </div>
    </div>

    <div className={classes.sectionHeader}>
    Section IV - PERMIT CANCELLATION / CLOSING
    </div>
    <div className={classes.secTwoContent}>
        <div className={classes.leftTd}>
           <h6>CLOSED BY</h6> 
        </div>
        <div className={classes.rightTd}>
           <p>{" "}</p> 
        </div>
    </div>
    <div className={classes.secTwoContent}>
        <div className={classes.leftTd}>
           <h6>DESIGNATION</h6> 
        </div>
        <div className={classes.rightTd}>
           <p>{" "}</p> 
        </div>
    </div> */}
         </div>
      </>
   )
}

export default PrintPdf