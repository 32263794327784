import './styles/style.css';
import './styles/globals.css';
import './styles/tailwind.css';
import './index.css';
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/js/dist/modal";

import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './pages/login/login';
import Layout from './components/layout/layout';
import Dashboard from './pages/dashboard/dashboard';
import Profile from './pages/profile/profile';
import ScrollToTop from './components/scrollToTop';
import ForgetPassword from './pages/forgetPassword/forgetPassword';
import Analytics from './components/Analytics/Analytics';
import Teams from './pages/teams/teams';
import Task from './pages/Task/task';
import Project from './pages/project/project';
import EditCategory from './pages/editCategory/editCategory';
import Safety from './pages/safety/safety';
import Eptw from './pages/eptw/eptw';
import CompanyProfile from './pages/companyProfile/companyProfile';
import ProjectTaskTable from './components/project/projectTaskTable/ProjectTaskTable';
import Editproject from './components/project/projectDrawer/editproject';
// import ChangePassword from './pages/resetPassword/changePassword';
import Template from './pages/templates';
import ContractorView from './pages/contractorView/contractorView';
import TaskNevigationTable from './components/TaskNevigationTable/TaskNevigationTable';
import UserNewPassword from './pages/userNewPassword/userNewPassword';
import ChangePassword from './pages/chnagePassword/changePassword';
import ResetConfirmPasswordByEmail from './pages/resetConfrimPasswordByEmail/resetConfirmPasswordByEmail';
import { useDispatch, useSelector } from 'react-redux';
import { loginVerify } from './redux/features/auth/authSilice';
import { useEffect } from 'react';
import PinPage from './components/PinPage/Pin';
import SafetyDashboard from './pages/SafetyDashBoard/SafetyDashboard';
import SafetyHome from './pages/safety/SafetyHome/SafetyHome';
import SafetyManagement from './pages/safety/SafetyManagement/SafetyManagement';
import VideoPage from './pages/videos/VideoPage';
import QuizTemplateStepper from './pages/templatesquiz';
import InductionManagement from './pages/safety/InductionManagement/InductionManagement';
import NotFoundPage from './components/404/NotFoundPage';
import SafetySession from './pages/safetySession/safetySession';
import SuccessInduction from './components/SuccessInduction';
import SafetyHomeDashboard from './pages/safety/SafetyHomeDashboard/SafetyHomeDashboard';
import FaileInduction from './components/FailedInduction';
import FoxUserView from './pages/foxUserView/foxUserView';

function PrivateRoute({ component: Component, path, exact }) {
  return (
    <Route
      path={path}
      exact={exact}
      render={(props) => (localStorage.getItem('accessToken') ? (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Layout>
          <Component {...props} />
        </Layout>
      ) : (
        // <Redirect to={{ pathname: '/login' }} />
        <Redirect to="/login" />
      ))}
    />
  );
}


function App() {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const getToken = ()=>{
      window.addEventListener("message" , function(e){
      if (e.origin !== "https://ncia.foxmy.io") {
        return
      }
      const data = JSON.parse(e.data)
      localStorage.setItem("accessToken" , JSON.stringify(data.accessToken))
      // localStorage.setItem("refreshToken" , JSON.stringify(data.refreshToken))
    })
    }
    getToken()
  }, [])
//   "drawing"
// "uploadedFile"

  // const [sign, setSign] = useState(null);
  // const [signFile, setSignFile] = useState(null);
  // const [signFilePhoto, setSignFilePhoto] = useState(null);
  // const [signUrlImg, setSignUrlImg] = useState(null);
  
  // Function to check token validity
  const checkTokenValidity = () => {
    const storedToken = localStorage.getItem('accessToken');
    if (storedToken) {
      const cleanedToken = storedToken.replace(/"/g, ''); // Remove all double quotes
      const tokenData = { token: cleanedToken };
      dispatch(loginVerify(tokenData))
        .then((response) => {
          // Check the response from the API
          if (response && response.code === 'token_not_valid') {
            // Handle the case where the token is invalid
            localStorage.removeItem('accessToken');
            history.push('/login');
            // console.log('Token is invalid or expired');
          } else {
            // Handle the case where the token is valid
            // console.log('Token is valid');
          }
        })
        .catch((error) => {
          // Handle any errors that occur during the API call
          console.error('API error:', error);
        });
    }
  };

  // Use a useEffect to run the token validity check on component mount
  useEffect(() => {
    checkTokenValidity();

    // Set up an interval to run the check every hour
    const intervalId = setInterval(checkTokenValidity, 3600000); // 3600000 milliseconds = 1 hour
    // console.log("Checking validation");
    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [dispatch, history]);

  // Rest of your component code

  const Token = localStorage.getItem('accessToken');

  return (
    <div className="App">
      <main>
        <ScrollToTop />
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgot-password" component={ForgetPassword} />
          <Route exact path="/fill-status" component={SafetyDashboard} />
          <Route exact path="/fill_form/:ptwId/:contractorId/" component={ContractorView} />
          <Route exact path="/user-new-password/:uidb/:token" component={UserNewPassword} />
          <Route exact path="/reset-confirm-password" component={ResetConfirmPasswordByEmail} />
          <Route exact path="/video_induction_session/details/:param1/:param2" component={SafetySession} />
          <Route exact path="/view-status/:sessionId/:contractorId/:id/:encoded" component={PinPage} />
          <Route exact path="/induction/Success" component={SuccessInduction} />
          <Route exact path="/induction/Fail" component={FaileInduction} />
          <Route exact path="/404" component={NotFoundPage} />
          <PrivateRoute exact path="/safetyDashBoard" component={SafetyHomeDashboard} />
          <PrivateRoute exact path="/" component={Project} />
          <PrivateRoute exact path="/profile" component={Profile} />
          <PrivateRoute exact path="/teams" component={Teams} />
          <PrivateRoute exact path="/task" component={Task} />
          <PrivateRoute exact path="/project" component={Project} />
          <PrivateRoute exact path="/forms" component={Eptw} />
          <PrivateRoute exact path="/forms/:form_id" component={FoxUserView} />
          <PrivateRoute exact path="/analytics" component={Analytics} />
          <PrivateRoute exact path="/templates" component={Template} />
          {/* <PrivateRoute exact path="/templates-quiz" component={QuizTemplateStepper} /> */}
          <PrivateRoute exact path="/editCategory" component={EditCategory} />
          <PrivateRoute exact path="/safety" component={SafetyHome} />
          {/* <PrivateRoute exact path="/safety-induction" component={InductionManagement} /> */}
          {/* <PrivateRoute exact path="/safety-home" component={SafetyHome} /> */}
          {/* <PrivateRoute exact path="/safety-video" component={Safety} /> */}
          <PrivateRoute exact path="/company-profile" component={CompanyProfile} />
          <PrivateRoute exact path="/project-task-table" component={ProjectTaskTable} />
          <PrivateRoute exact path="/editproject" component={Editproject} />
          <PrivateRoute exact path="/change-password" component={ChangePassword} />
          <PrivateRoute exact path="/project/:ID" component={Project} />
          <PrivateRoute exact path="/task-neviagtion-table/:ID" component={TaskNevigationTable} />
          {/* <PrivateRoute exact path="/videos" component={VideoPage} /> */}
          <Route path="/*" component={NotFoundPage} />
          {Token ? <Redirect to="/project" /> : <Redirect to="/login" />}
        </Switch>
      </main>
      <ToastContainer draggable={false} autoClose={4000} limit={1} />
    </div>

  );
}

export default App;
