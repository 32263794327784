/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/self-closing-comp */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable consistent-return */

import React, { useEffect, useState } from 'react'
import { Drawer } from "@mui/material";
import { toast } from "react-toastify"
import { useDispatch, useSelector } from 'react-redux';
import {
  setContractor,
  createEptw,
  setTemplateId,
} from '../../../redux/features/eptw/eptwSlice';
import { SelectApprovers, SelectContractors, SelectTemplates } from '../../../components/selectPersons/SelectPersons';
import { setAssignedTo } from '../../../redux/features/approver/approverSlice';
import { getAllUsers } from '../../../redux/features/user/userSlice';

function AddEptwModal({ openModalCreate, closeModalCreate }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllUsers());
  }, [])

  const {
    contractors
  } = useSelector(state => state.project)
  const {
    templates,
  } = useSelector(state => state.template)
  const {
    templateId,
    contractor,
    eptwModalCreate
  } = useSelector(state => state.eptw)

  const {
    assigned_to,
  } = useSelector(state => state.approver);
  const { allUsers } = useSelector(state => state.user)



  const permitApprovers = [
    { template_id: null, approvers: [] }
  ]

  const [permitApproversState, setPermitApproversState] = useState(permitApprovers)

  const handelAddNewPermit = () => {
    setPermitApproversState([...permitApproversState, { template_id: 1, approvers: [1, 2, 3] }])
  }
  const handelRemoveLastPermit = () => {
    const permitArray = [...permitApproversState]
    permitArray.pop()
    setPermitApproversState(permitArray)
  }
  const handelTemplate = (e, i) => {
    setPermitApproversState(permitApproversState.map((item, index) => {
      if (index === i) {
        return { ...item, template_id: e?.value }
      } else {
        return item
      }
    }))
    // dispatch(setTemplateId(e))
  }
  //   const handelAssignedTo = (selectedOptions)=>{
  //     const previousSelectedLength = assignedTo.length;
  //     const newSelectedLength = selectedOptions.length;
  //     if (newSelectedLength > previousSelectedLength && newSelectedLength > 3) {
  //       toast.warning('You cannot add more than three approvers', {
  //         toastId: 'ApproverGeWarning',
  //       });
  //     }else{
  //       dispatch(setAssignedTo(selectedOptions))
  //     }
  // }

  const handelAssignedTo = (e, i) => {
    console.log("Index", i);
    setPermitApproversState(permitApproversState.map((item, index) => {
      if (index === i) {
        return { ...item, approvers: e?.map(ele => ele?.value) }
      } else {
        return item
      }
    }))
    // dispatch(setTemplateId(e))
  }

  console.log("permitApproversState", permitApproversState);
  console.log("templateId", templateId);
  const handelContractor = (e) => {
    dispatch(setContractor(e.value))
  }
  const data = {
    contractor: contractor ? contractor : null,
    template_approvers: permitApproversState
  }

  const handelSave = async (e) => {
    e.preventDefault()
    const isEmpty = permitApproversState.some(item => !item.template_id);
    console.log(isEmpty);
    if (!isEmpty) {
      await dispatch(createEptw(data))
    } else {
      toast.warning('Please Fill The Required Fields', {
        toastId: 'AddPtwWarning',
      });
    }
  }


  useEffect(() => {
    dispatch(setAssignedTo([]))
  }, [eptwModalCreate])


  return (
    <Drawer
      anchor="right"
      open={openModalCreate}
      onClose={closeModalCreate}
      PaperProps={{
        style: {
          width: '100%',
          maxWidth: '600px',
          height: '100%',
        },
      }}
    >
      <form
        onSubmit={handelSave}
        className="h-100 w-100" >
        <div className="h-100">
          <div className="navbar sticky-top col-lg-12 m-0 modal-header-background page-profile-header pr-0">

            <div className="col-12 mb-2">
              <div className="row px-2">
                <div className="my-auto">
                  <b>
                    Add Permit
                  </b>
                </div>
                <button onClick={closeModalCreate} type='button' className="btn secondary-btn ml-auto" data-dismiss="modal" >
                  <div className="fas fa-times"></div>
                </button>
              </div>
            </div>
          </div>
          <div className="" style={{ minHeight: "78.5%" }}>
            <div className="tab-content col-lg-12 col-sm-12" id="custom-tabs-two-tabContent">
              <div id="maintenance-tab" role="tabpanel" aria-labelledby="custom-tabs-two-maintenance-tab">
                <div className="card-body">
                  <div className="row pb-5">
                    <div className="col-lg-12 col-sm-12 pt-3">
                      <label className="font-weight-normal">Requester</label>
                      <SelectContractors contractors={contractors} handelChange={handelContractor} contractor={contractor} />
                    </div>
                    {permitApproversState?.map((item, index) => {
                      return <>
                        <div className="col-lg-12 col-sm-12 pt-3">
                          <label className="font-weight-normal ">Permit ({index + 1})</label><label className="font-danger m-0">*</label>
                          <SelectTemplates templates={templates} handelChange={handelTemplate} index={index} />
                        </div>
                        <div className="col-lg-12 col-sm-12 pt-3">
                          <label className="font-weight-normal">Approvers</label>
                          <SelectApprovers teams={allUsers} assignedTo={assigned_to} handelAssignedTo={handelAssignedTo} index={index} />
                        </div>
                      </>
                    })}
                    <div className="col-lg-12 col-sm-12 pt-4">
                      <button onClick={handelAddNewPermit} type='button' className='secondary-btn btn mr-3'> Add New Permit</button>
                      {permitApproversState.length > 1 &&
                        <button onClick={handelRemoveLastPermit} type='button' className='secondary-btn btn'> Remove Last Permit</button>
                      }
                    </div>
                    {/* <div className="col-lg-12 col-sm-12 pt-3">
                      <label className="font-weight-normal ">Permit</label><label className="font-danger m-0">*</label>
                      <SelectTemplates templates={templates} handelChange={handelTemplate} />
                    </div>
                    <div className="col-lg-12 col-sm-12 pt-3">
                      <label className="font-weight-normal">Approvers</label>
                      <SelectPersons teams={allUsers} assignedTo={assigned_to} setAssignedTo={setAssignedTo} />
                    </div> */}
                  </div>
                  <p className="font-danger float-center" id="code_error"></p>
                </div>
              </div>
            </div>
          </div>
          <div className=" m-0 p-2 page-profile-footer" >
            <button
              className="btn primary-btn"
              type="submit"

            >
              Save
            </button>
          </div>
        </div>
      </form>
    </Drawer>

  )
}

export default AddEptwModal


