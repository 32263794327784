import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button'
import NotFoundImage from '../../assets/img/404.png'
const backButtonStyle = {

    backgroundColor: '#f63854',
    color: 'white',
    '&:hover': {
        backgroundColor: '#f63854',
    },
};
const NotFoundPage = () => {
    return (
        <>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="80vh"
            >
                <img src={NotFoundImage} alt="404 Not found" />
            </Box>
            <Box
                display="flex"
                justifyContent="center">
                <Typography variant="h4" gutterBottom>
                    Oops! This page could not be found.      <Button style={backButtonStyle}
                        onClick={() => { window.location.href = "https://ncia.project.foxmy.io"; }} variant="contained">Go back</Button>
                </Typography>
            </Box>
        </>

    )
}

export default NotFoundPage