/* eslint-disable import/prefer-default-export */
import {
  faBoxesPacking,
  faChartColumn,
  faSliders,
  faTachometerAlt,
  faTag,
  faUsers,
  faCheck,
  faFolderOpen,
  faHandshake,
  faFire
} from '@fortawesome/free-solid-svg-icons'
import { faClipboardCheck } from '@fortawesome/fontawesome-free-solid'
import Dashboard from '../pages/dashboard/dashboard'
import Teams from '../pages/teams/teams'
import AnalyticsReport from '../pages/analyticsReport/analyticsReport'
import Eptw from '../pages/eptw/eptw'
import Project from '../pages/project/project'
import EditCategory from '../pages/editCategory/editCategory'
import Task from '../pages/Task/task'
import Safety from '../pages/safety/safety'
import Template from '../pages/templates'
import SafetyManagement from '../pages/safety/SafetyManagement/SafetyManagement'

export const tabs = [
  // {
  //   text: 'Analytic Report',
  //   id: 1,
  //   path: '/analytics',
  //   style: 'mb-4',
  //   icon: faChartColumn,
  //   tabComponent: AnalyticsReport
  // },
  {
    text: 'Project',
    id: 1,
    path: '/project',
    style: 'mb-4',
    icon: faFolderOpen,
    tabComponent: Project
  },
  {
    text: 'Permit',
    id: 2,
    path: '/forms',
    style: 'mb-4',
    icon: faHandshake,
    tabComponent: Eptw
  },
  {
    text: 'Permit Templates',
    id: 3,
    path: '/templates',
    style: 'mb-4',
    icon: faFolderOpen,
    tabComponent: Template
  },
  {
    text: 'Safety Induction',
    id: 4,
    path: '/safetyDashBoard',
    icon: faFire,
    style: 'mb-4',
    tabComponent: SafetyManagement
  },
  {
    text: 'Teams',
    id: 5,
    path: '/teams',
    style: 'mb-4',
    icon: faUsers,
    tabComponent: Teams
  },
  {
    text: 'Settings',
    id: 6,
    path: '/editCategory',
    icon: faSliders,
    style: 'mb-4',
    tabComponent: EditCategory
  }
]
