import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import Box from '@mui/material/Box'
import StepLabel from '@mui/material/StepLabel'
import classes from '../../styles/asset.module.css'
import LooksOneRoundedIcon from '@mui/icons-material/LooksOneRounded';
import LooksTwoRoundedIcon from '@mui/icons-material/LooksTwoRounded';
import Looks3RoundedIcon from '@mui/icons-material/Looks3Rounded';
import SettingsIcon from '@mui/icons-material/Settings'
import VideoLabelIcon from '@mui/icons-material/VideoLabel'
import VisibilityIcon from '@mui/icons-material/Visibility'
import styles from "../../styles/asset.module.css";
import StepConnector, {
  stepConnectorClasses
} from '@mui/material/StepConnector'
import TextField from '@mui/material/TextField'
import EptwTemplateDetails from './EptwTemplateDetails'
import { useHistory } from 'react-router-dom'
import {
  createTemplate,
  setIsErr,
  setRevisionNumber,
  setRevisionDate,
  setDocumentNumber,
  setTemplateNameError,
  updateTemplate
} from '../../redux/features/template/templateSclice'
import logo from "../../assets/img/FOX-Logo3.png"
import TemplateReview from './templateReview'
import { useReactToPrint } from 'react-to-print';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1
  }
}))

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)'
  })
}))

function ColorlibStepIcon(props) {
  const { active, completed, className } = props

  const icons = {
    1: <LooksOneRoundedIcon />,
    2: <LooksTwoRoundedIcon />,
    3: <Looks3RoundedIcon />
  }

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  )
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node
}
const steps = ['Select a name for template', 'Enter details', 'Review Template']

export default function Steps({
  inputList,
  customSection,
  revisionDate,
  revisionNumber,
  documentNumber,
  setCustomSection
}) {
  const history = useHistory()
  const [addCustomInputs, setAddCustomInputs] = React.useState(false)
  const [disabledFeild, setDisabledFeild] = React.useState(false)
  const [disabledSection, setDisabledSection] = React.useState(false)
  const [showPreview, setShowPreview] = React.useState(false)
  const { step, templateName, templateNameError, template, templateCategory } = useSelector(
    state => state.template
  )
  const dispatch = useDispatch()
  console.log(templateCategory, "templateCategory");

  React.useEffect(() => {
    if (templateCategory && templateCategory !== "PTW") {
      setShowPreview(true)
    }
  }, [templateCategory])

  const handleNextStep = () => {
    if (templateName) {
      if (step < steps.length - 1) {
        // dispatch(setStep(step + 1))
        setShowPreview(true)
      }
    } else {
      dispatch(setTemplateNameError(true))
    }
  }
  const handlePrevStep = () => {
    if (step < steps.length + 1) {
      // dispatch(setStep(step - 1))
      setShowPreview(false)
    }
  }

  const addCustomSectionHandler = type => {
    if (type === 'section') {
      if (customSection.length > 0) {
        if (customSection.some(obj => obj.title === '')) {
          return
        }
        if (
          customSection[customSection.length - 1].fields.length === 0 ||
          customSection.some(obj => obj.fields.some(item => item.name === ''))
        ) {
          return
        }
        setCustomSection([...customSection, { title: '', fields: [] }])
      }
      setCustomSection([...customSection, { title: '', fields: [] }])
    }

    if (type === 'feild') {
      if (customSection.some(obj => obj.title === '')) {
        return
      }
      if (
        customSection.some(obj => obj.fields.some(item => item.name === ''))
      ) {
        return
      }
      const idx = customSection?.length - 1
      const updatedItems = customSection.map((item, index) => {
        if (index === idx) {
          return {
            ...item,
            fields: [...item.fields, { name: '', type: 'String' }] // Update the title within the 'section' object
          }
        }
        return item
      })
      setCustomSection(updatedItems)
    }
  }

  const addnewFeildHandler = (i, type, idx) => {
    const arr = [...customSection];
    const sectionCopy = { ...arr[i] }; // Shallow copy of the section
    const fieldsCopy = [...sectionCopy.fields]; // Shallow copy of the fields array

    if (type === 'feild') {
      fieldsCopy.splice(idx + 1, 0, { name: '', type: 'String' });
    }

    sectionCopy.fields = fieldsCopy; // Update the section copy with the new fields array
    arr[i] = sectionCopy; // Update the original array with the modified section copy

    setCustomSection(arr);
  };


  const removeCustomSectionHandler = (i, type, idx) => {
    const list = [...customSection];
    if (type === 'section') {
      const updatedList = [...list.slice(0, i), ...list.slice(i + 1)];
      setCustomSection(updatedList);
    }
    if (type === 'feild') {
      const sectionCopy = { ...list[i] };
      const updatedFields = [...sectionCopy.fields.slice(0, idx), ...sectionCopy.fields.slice(idx + 1)];
      sectionCopy.fields = updatedFields;

      const updatedList = [...list.slice(0, i), sectionCopy, ...list.slice(i + 1)];
      setCustomSection(updatedList);
    }
  };

  const handleDocumentNumberChange = async (e) => {
    await dispatch(setDocumentNumber(e))
  }
  const handleRevisionNumberChange = async (e) => {
    await dispatch(setRevisionNumber(e))
  }
  const handleRevisionDateChange = async (e) => {
    await dispatch(setRevisionDate(e))
  }

  const handelCutomSectionChange = (e, i, id, type, idx) => {
    const { name, value } = e.target;
    setCustomSection((prevCustomSection) => {
      return prevCustomSection.map((item, index) => {
        console.log("item in setCustomSection", item);
        if (index !== i) {
          return item;
        }

        if (type === 'title') {
          return { ...item, title: value };
        } else if (type === 'feild') {
          const updatedFields = item.fields.map((field, fieldIndex) => {
            if (fieldIndex !== idx) {
              return field;
            }

            if (name === 'name') {
              return { ...field, name: value };
            } else if (name === 'type') {
              return { ...field, type: value };
            }
            else if (name === 'isRequired') {
              return { ...field, isRequired: e.target?.checked };
            }
            return field;
          });

          return { ...item, fields: updatedFields };
        }

        return item;
      });
    });
  };
  const conditionMet = inputList?.some(obj => obj.name === '')

  const handelSaveTemplate = () => {
    const sections = [];
    customSection?.forEach(item => {
      const sectionName = item.title;
      const sectionFields = item.fields.map(field => ({ name: field.name, type: field.type, isRequired: field.isRequired }));
      sections.push({ title: sectionName, fields: sectionFields });
    });
    const data = {
      name: templateName,
      sections,
      revision_date: revisionDate,
      revision_number: revisionNumber,
      document_number: documentNumber,
      saftey_percautions: 'test \n test'
    }
    if (templateName) {
      if (template?.id) {
        dispatch(updateTemplate({ id: template?.id, data: data }))
      } else {
        dispatch(createTemplate(data))
      }
    } else {
      dispatch(setIsErr(true))
    }
  }

  const { company } = useSelector((state) => state.company);
  const componentRef = React.useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
});

  const pdfContent = <div style={{ paddingBottom: '1.25rem', display: 'none' }}>
    <div className="card-body" ref={componentRef}>
      <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-0">
        <div className="col col-lg-3 col-md-4 col-sm-4 col-xs-12  mb-2 logo"
          style={{ marginTop: "-35px", position: "absolute" }}
        >
          <img src={company?.logo ? company?.logo : logo} alt='logo' style={{ width: "80px", height: "80px", objectFit: "contain", borderRadius: "50%" }} />
        </div>
        <div className="col-lg-9 col-sm-12 col-md-8 mt-5 col-xs-12">
          <h1 style={{ fontWeight: "400", marginLeft: "16px", textAlign: "center" }}>{templateName}</h1>
        </div>
      </div>
      <div className="mt-5">
        <center>
          <div className="card col-lg-9 col-sm-12 col-xs-12 col-md-12 text-left"
            style={{ display: "block" }}
          >
            <div className="tab-content col-lg-12 col-sm-12" id="custom-tabs-two-tabContent">
              <div id="maintenance-tab" role="tabpanel" aria-labelledby="custom-tabs-two-maintenance-tab">
                <div className="col-lg-12 col-sm-12 pt-3">
                  <div className="pdf-wraper">
                    <div>
                      <label className="font-weight-normal">Document Number<label className="font-danger m-0">*</label></label>
                      <input disabled required name='meter_name' type="text" className="form-control mb-2"
                        placeholder="Document Number"
                        value={documentNumber || ""}
                      />
                    </div>
                    <div>
                      <label className="font-weight-normal">Permit Number<label className="font-danger m-0">*</label></label>
                      <input disabled required name='text' type="text" className="form-control mb-2"
                        placeholder="Permit Number"
                        value={'Auto Generated Running Number'}
                      />
                    </div>
                    <div>
                      <label className="font-weight-normal">Revision Number<label className="font-danger m-0">*</label></label>
                      <input disabled required name='meter_name' type="text" className="form-control mb-2"
                        placeholder="Revision Number"
                        value={revisionNumber || ""}
                      />
                    </div>
                    <div>
                      <label className="font-weight-normal">Revision Date<label className="font-danger m-0">*</label></label>
                      <input disabled required name='date' type="date" className="form-control mb-2"
                        placeholder="Revision Date"
                        value={revisionDate || ""}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body pb-3">
                  {customSection.map((item, index) => (
                    <div className={classes.templateSection} key={index}>
                      {console.log("the itemss", item)}
                      <h3 className={classes.sectionTitle}>{item.title}</h3>
                      <ul className={classes.sectionfields}>
                        {item?.fields.map((ele, idx) => {
                          console.log("an element", ele)
                          const type = ele.type
                          return (type === "String" || type === 'Date' || type === 'Number' || type === 'Attachment') ? <div className="col-lg-12 col-sm-12">
                            <label className="font-weight-normal">
                              {ele.name?.replace(/_/g, " ")}
                              {ele.isRequired === true && <label className="font-danger m-0">*</label>
                              }</label>
                            <input
                              type={type === "String" ? "Text" : type === "Number" ? "number" : type === "Date" ? "date" : type === "Number" ? "number" : "file"}
                              className="form-control mb-2"
                              disabled
                              // placeholder={ele.type}
                              placeholder={null}
                            />
                          </div> : type == 'Checkbox' ? <div className="col-lg-12 col-sm-12">
                            <div className={classes.templateCheckbox}>
                              <input type="Checkbox" disabled />
                              <label className="font-weight-normal">{ele.name}</label>
                            </div> </div> :
                            type == 'Description' ? <div className="col-lg-12 col-sm-12"><h6>{ele.name}</h6></div>
                              : <div>
                                <label className="font-weight-normal">{ele.name}{ele.isRequired === true && <label className="font-danger m-0">*</label>}</label>
                                <div className="col-lg-12 col-sm-12"><div className='sign-image'><h3 style={{ marginTop: '30px', textAlign: 'center', color: '#858585' }}>{ele.name}{ele.isRequired === true && <label className="font-danger m-0">*</label>} [Signature field]</h3></div></div>
                              </div>
                        })}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </center >
      </div>
    </div>
  </div>

  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <Stepper
        alternativeLabel
        activeStep={step}
        connector={<ColorlibConnector />}
      >
      </Stepper>
      {/* Render different content for each step */}
      {step === 0 && (
        <>
          {pdfContent}
          {!showPreview && (
            <div>
              <EptwTemplateDetails
                setRevisionDate={handleRevisionDateChange}
                setDocumentNumber={handleDocumentNumberChange}
                setRevisionNumber={handleRevisionNumberChange}
                addCustomSectionHandler={addCustomSectionHandler}
                removeCustomSectionHandler={removeCustomSectionHandler}
                handelCutomSectionChange={handelCutomSectionChange}
                addCustomInputs={addCustomInputs}
                customSection={customSection}
                setCustomSection={setCustomSection}
                setAddCustomInputs={setAddCustomInputs}
                addnewFeildHandler={addnewFeildHandler}
                revisionDate={revisionDate}
                revisionNumber={revisionNumber}
                documentNumber={documentNumber}
              />
              <button
                className={
                  !customSection[0]?.title || !customSection[0]?.fields[0]?.name || !documentNumber || !revisionNumber || !revisionDate || !templateName
                    ? `${classes.disableBtn} mr-2`
                    : `${classes.threeDButton} mr-2`
                }
                onClick={handelSaveTemplate}
                disabled={
                  !customSection[0]?.title || !customSection[0]?.fields[0]?.name || !documentNumber || !revisionNumber || !revisionDate || !templateName
                }
              >
                Save
              </button>
              <button
                className={
                  !customSection[0]?.title || !customSection[0]?.fields[0]?.name || !documentNumber || !revisionNumber || !revisionDate || !templateName
                    ? `${classes.disableBtn} mr-2`
                    : `${classes.threeDButton} mr-2`
                }
                onClick={handleNextStep}
                disabled={
                  !customSection[0]?.title || !customSection[0]?.fields[0]?.name || !documentNumber || !revisionNumber || !revisionDate || !templateName
                }
              >
                Preview
              </button>
              <button
                className={
                  !customSection[0]?.title || !customSection[0]?.fields[0]?.name || !documentNumber || !revisionNumber || !revisionDate || !templateName
                    ? `${classes.disableBtn}`
                    : `${classes.threeDButton}`
                }
                onClick={handlePrint}
                disabled={
                  !customSection[0]?.title || !customSection[0]?.fields[0]?.name || !documentNumber || !revisionNumber || !revisionDate || !templateName
                }
              >
                Print
              </button>
            </div>)}
          {showPreview && (
            <div>
              <TemplateReview
                templateName={templateName}
                customSection={customSection}
                revisionDate={revisionDate}
                revisionNumber={revisionNumber}
                documentNumber={documentNumber}
                templateCategory={templateCategory}
              />
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
              {templateCategory && templateCategory === "PTW" && <button
                  className={
                    !customSection[0]?.title || !customSection[0]?.fields[0]?.name || !documentNumber || !revisionNumber || !revisionDate || !templateName
                      || (templateCategory && templateCategory !== "PTW") ? `${classes.disableBtn} mr-4`
                      : `${classes.threeDButton} mr-4`
                  }
                  onClick={handelSaveTemplate}
                  disabled={
                    !customSection[0]?.title || !customSection[0]?.fields[0]?.name || !documentNumber || !revisionNumber || !revisionDate || !templateName
                    || (templateCategory && templateCategory !== "PTW")
                  }
                >
                  Save
                </button>}
                {templateCategory && templateCategory === "PTW" && <button
                  className={(templateCategory && templateCategory !== "PTW") ? classes.disableBtn : `${classes.threeDButton}`}
                  onClick={handlePrevStep}
                  disabled={(templateCategory && templateCategory !== "PTW")}
                >
                  Back
                </button>}
              </Box>
            </div>
          )}
        </>
      )}
    </Stack>
  )
}
